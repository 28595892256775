@font-face {
  font-family: 'proxima-nova-thin';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/proxima/ProximaNovaT-Thin.woff") format('woff'), url("../fonts/proxima/ProximaNovaT-Thin.ttf") format('truetype');
}
@font-face {
  font-family: 'proxima-nova-regular';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/proxima/ProximaNova-Regular.woff") format('woff'), url("../fonts/proxima/ProximaNova-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'proxima-nova-light';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/proxima/ProximaNova-Light.woff") format('woff'), url("../fonts/proxima/ProximaNova-Light.ttf") format('truetype');
}
@font-face {
  font-family: 'proxima-nova-semibold';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/proxima/ProximaNova-Semibold.woff") format('woff'), url("../fonts/proxima/ProximaNova-Semibold.ttf") format('truetype');
}
@font-face {
  font-family: 'proxima-nova-bold';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/proxima/ProximaNova-Bold.woff") format('woff'), url("../fonts/proxima/ProximaNova-Bold.ttf") format('truetype');
}
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
em,
strong,
abbr,
acronym,
blockquote,
q,
cite,
ins,
del,
dfn,
a,
div,
span,
pre,
hr,
address,
br,
b,
i,
sub,
big,
small,
tt,
table,
tr,
caption,
thead,
tbody,
tfoot,
col,
colgroup,
form,
input,
label,
textarea,
button,
fieldset,
legend,
select,
option,
ul,
ol,
li,
dl,
dt,
dd,
code,
var,
samp,
img,
object,
param,
map,
area {
  padding: 0;
  margin: 0;
  border: none;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
header,
footer,
section,
aside,
figure,
figcaption,
input {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 15px;
  vertical-align: baseline;
  background: transparent;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input[type=button],
input[type=submit],
button {
  cursor: pointer;
}
button {
  border: 0;
}
:focus {
  outline: none;
}
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: none;
  outline: none;
}
input::-ms-clear {
  width: 0;
  height: 0;
}
input,
textarea {
  resize: none;
  user-select: text;
}
::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}
::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}
a {
  text-decoration: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.owl-item {
  transform: translateZ(0);
}
img {
  display: block;
}
button {
  background: transparent;
}
input,
textarea {
  font-family: 'proxima-nova-regular';
  -webkit-appearance: none;
  border-radius: 0;
}
html,
body {
  height: 100%;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  font-family: 'proxima-nova-regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
* {
  margin: 0;
  padding: 0;
}
header,
footer,
section {
  max-width: 100vw;
  overflow-x: hidden;
}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  max-width: 100vw;
  overflow-x: hidden;
}
.b-wrapper {
  width: 100%;
  display: table;
  height: 100%;
}
.content {
  display: table-row;
  width: 100%;
  height: 100%;
}
.section {
  width: 100%;
}
.hidden {
  display: none !important;
}
section .container {
  padding-top: 136px;
  padding-bottom: 136px;
  position: relative;
}
@media only screen and (max-width: 1700px) {
  section .container {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
@media only screen and (max-width: 991px) {
  section .container {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  section .container {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
section .container.to-right {
  padding-left: 10%;
}
@media only screen and (max-width: 1400px) {
  section .container.to-right {
    padding-left: 8%;
  }
}
@media only screen and (max-width: 991px) {
  section .container.to-right {
    padding-left: 30px;
  }
}
section .container.bgc-white {
  background-color: #fff;
}
section .container.bgc-white h2,
section .container.bgc-white h3,
section .container.bgc-white h4,
section .container.bgc-white h5,
section .container.bgc-white h6,
section .container.bgc-white .section-description {
  color: #000;
}
section .container.bgc-white:after {
  background-color: #fff;
}
section .container.bgc-black {
  background-color: #14171c;
}
section .container:after {
  position: absolute;
  content: '';
  display: block;
  width: 2000px;
  height: 100%;
  right: -1999px;
  top: 0;
}
.section_black {
  background-color: #000 !important;
  background: url("/assets/images/img/bg_black.jpg") center;
}
.section_black h2,
.section_black h3,
.section_black h4,
.section_black h5,
.section_black h6,
.section_black .section-description,
.section_black ul li {
  color: #fff;
}
.section_white {
  background-color: #fff !important;
}
.section_white h2,
.section_white h3,
.section_white h4,
.section_white h5,
.section_white h6,
.section_white .section-description {
  color: #000;
}
.container {
  max-width: 1540px;
  padding-left: 95px;
  padding-right: 95px;
  width: 100%;
}
@media only screen and (max-width: 1700px) {
  .container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .container {
    width: 100%;
    padding-left: 30px !important;
    padding-right: 30px !important;
    max-width: inherit;
  }
}
@media only screen and (max-width: 600px) {
  .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.row {
  margin-bottom: 0;
}
h1 {
  font-size: 74px;
  line-height: 82px;
  font-family: 'proxima-nova-bold';
  color: #fff;
}
@media only screen and (max-width: 1700px) {
  h1 {
    font-size: 72px;
    line-height: 80px;
  }
}
@media only screen and (max-width: 1400px) {
  h1 {
    font-size: 68px;
    line-height: 76px;
  }
}
@media only screen and (max-width: 1200px) {
  h1 {
    font-size: 64px;
    line-height: 72px;
  }
}
@media only screen and (max-width: 991px) {
  h1 {
    font-size: 58px;
    line-height: 62px;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 48px;
    line-height: 52px;
  }
}
@media only screen and (max-width: 400px) {
  h1 {
    font-size: 40px;
    line-height: 44px;
  }
}
h2,
h3 {
  font-size: 50px;
  line-height: 54px;
  font-family: 'proxima-nova-bold';
  color: #fff;
  margin-bottom: 36px;
}
@media only screen and (max-width: 1700px) {
  h2,
  h3 {
    font-size: 48px;
    line-height: 52px;
  }
}
@media only screen and (max-width: 1400px) {
  h2,
  h3 {
    font-size: 45px;
    line-height: 49px;
  }
}
@media only screen and (max-width: 1200px) {
  h2,
  h3 {
    font-size: 43px;
    line-height: 47px;
  }
}
@media only screen and (max-width: 991px) {
  h2,
  h3 {
    font-size: 40px;
    line-height: 44px;
  }
}
@media only screen and (max-width: 767px) {
  h2,
  h3 {
    font-size: 36px;
    line-height: 40px;
  }
}
h4,
h4 span {
  font-size: 38px;
  line-height: 47px;
  font-family: 'proxima-nova-bold';
  color: #fff;
}
@media only screen and (max-width: 1700px) {
  h4,
  h4 span {
    font-size: 36px;
    line-height: 45px;
  }
}
@media only screen and (max-width: 1400px) {
  h4,
  h4 span {
    font-size: 33px;
    line-height: 42px;
  }
}
@media only screen and (max-width: 1200px) {
  h4,
  h4 span {
    font-size: 31px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 991px) {
  h4,
  h4 span {
    font-size: 29px;
    line-height: 38px;
  }
}
@media only screen and (max-width: 767px) {
  h4,
  h4 span {
    font-size: 25px;
    line-height: 34px;
  }
}
h5 {
  font-size: 28px;
  line-height: 34px;
  font-family: 'proxima-nova-bold';
  color: #000;
}
@media only screen and (max-width: 1700px) {
  h5 {
    font-size: 26px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 1400px) {
  h5 {
    font-size: 24px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 767px) {
  h5 {
    font-size: 22px;
    line-height: 32px;
  }
}
h6 {
  font-size: 26px;
  line-height: 36px;
  color: #000;
  font-family: 'proxima-nova-bold';
  margin: 14px 0;
}
@media only screen and (max-width: 1700px) {
  h6 {
    font-size: 24px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 1400px) {
  h6 {
    font-size: 22px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 767px) {
  h6 {
    font-size: 20px;
    line-height: 28px;
  }
}
.section-description {
  font-size: 24px !important;
  line-height: 34px;
  color: #000;
  margin: 18px 0 23px;
  max-width: 830px;
}
@media only screen and (max-width: 1700px) {
  .section-description {
    font-size: 22px !important;
    line-height: 32px;
  }
}
@media only screen and (max-width: 1200px) {
  .section-description {
    font-size: 20px !important;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .section-description {
    font-size: 18px !important;
    line-height: 24px;
  }
}
.section-description a {
  font-size: 24px;
  line-height: 34px;
}
@media only screen and (max-width: 1700px) {
  .section-description a {
    font-size: 22px !important;
    line-height: 32px;
  }
}
@media only screen and (max-width: 1200px) {
  .section-description a {
    font-size: 20px !important;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .section-description a {
    font-size: 18px !important;
    line-height: 24px;
  }
}
.section-description span {
  color: #00c088;
  font-size: inherit;
  line-height: inherit;
}
.section-description strong {
  font-family: 'proxima-nova-bold';
  font-size: inherit;
  line-height: inherit;
}
.back-top__link,
a {
  color: #00c088;
  text-decoration: underline;
  font-size: 18px;
  line-height: 30px;
  transition: all 0.2s ease;
}
@media only screen and (max-width: 767px) {
  .back-top__link,
  a {
    font-size: 16px;
    line-height: 26px;
  }
}
.back-top__link:hover,
a:hover {
  text-decoration: none;
}
.back-top__link {
  display: inline-block;
}
strong {
  color: #000;
  font-family: 'proxima-nova-semibold';
  margin: 0 0 14px;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1400px) {
  strong {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  strong {
    font-size: 16px;
    line-height: 26px;
  }
}
p {
  color: #686868;
  margin: 0 0 14px;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1400px) {
  p {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  p {
    font-size: 16px;
    line-height: 26px;
  }
}
p a {
  font-size: 20px;
  line-height: 30px;
  text-decoration: none;
}
@media only screen and (max-width: 1400px) {
  p a {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  p a {
    font-size: 16px;
    line-height: 26px;
  }
}
ul.custom-list-green {
  margin: 0 0 20px;
}
ul.custom-list-green li {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px;
  position: relative;
  padding-left: 52px;
  color: #686868;
}
ul.custom-list-green li:before {
  content: '';
  display: block;
  position: absolute;
  width: 30px;
  height: 1px;
  background-color: #00c088;
  left: 0;
  top: 15px;
}
@media only screen and (max-width: 1400px) {
  ul.custom-list-green li {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  ul.custom-list-green li {
    font-size: 16px;
    line-height: 26px;
  }
}
.list_custom li {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 28px;
  position: relative;
  padding-left: 34px;
  color: #000;
}
.list_custom li:before {
  content: '';
  display: block;
  position: absolute;
  width: 14px;
  height: 2px;
  background-color: #00c088;
  left: 0;
  top: 15px;
}
@media only screen and (max-width: 1400px) {
  .list_custom li {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .list_custom li {
    font-size: 16px;
    line-height: 26px;
  }
}
.list_custom li a {
  font-size: 20px;
  line-height: 30px;
  text-decoration: none;
}
@media only screen and (max-width: 1400px) {
  .list_custom li a {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .list_custom li a {
    font-size: 16px;
    line-height: 26px;
  }
}
.list_check li {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 24px;
  position: relative;
  padding-left: 40px;
  color: #000;
}
.list_check li:before {
  content: '';
  display: block;
  position: absolute;
  width: 13px;
  height: 7px;
  left: 0;
  top: 9px;
  border: 2px solid #00c088;
  transform: rotate(-45deg);
  border-top: none;
  border-right: none;
}
@media only screen and (max-width: 1400px) {
  .list_check li {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .list_check li {
    font-size: 16px;
    line-height: 26px;
  }
}
.list_check li a {
  font-size: 20px;
  line-height: 30px;
  text-decoration: none;
}
@media only screen and (max-width: 1400px) {
  .list_check li a {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .list_check li a {
    font-size: 16px;
    line-height: 26px;
  }
}
.cards-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px 0;
}
@media only screen and (max-width: 1024px) {
  .cards-wrap {
    margin-top: 40px;
  }
}
.cards-wrap .card_custom {
  width: 32%;
  min-height: 580px;
  position: relative;
  perspective: 2000px;
  margin: 10px 0;
  text-decoration: none;
}
@media only screen and (max-width: 1300px) {
  .cards-wrap .card_custom {
    min-height: 610px;
  }
}
@media only screen and (max-width: 1024px) {
  .cards-wrap .card_custom {
    min-height: inherit;
    width: 100%;
  }
}
.cards-wrap .card_custom h4 {
  color: #00c088;
}
.cards-wrap .card_custom .front,
.cards-wrap .card_custom .back {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 50px 45px;
  display: flex;
  transition: 1s;
  backface-visibility: hidden;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1200px) {
  .cards-wrap .card_custom .front,
  .cards-wrap .card_custom .back {
    padding: 35px 30px;
  }
}
.cards-wrap .card_custom .front {
  border: 1px solid #00c088;
  background-color: rgba(0,0,0,0.4);
  color: #00c088;
  align-items: flex-end;
  box-sizing: border-box;
}
@media only screen and (max-width: 1024px) {
  .cards-wrap .card_custom .front {
    display: none;
  }
}
.cards-wrap .card_custom .front .front__image {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 80px;
}
.cards-wrap .card_custom .back {
  border: 1px solid #fff;
  background-color: #fff;
  transform: rotateY(180deg);
  box-sizing: border-box;
  position: relative;
}
.cards-wrap .card_custom .back .card__link {
  position: absolute;
  bottom: 40px;
  left: 45px;
  color: #00c088;
  text-decoration: underline;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 0;
}
@media only screen and (max-width: 1200px) {
  .cards-wrap .card_custom .back .card__link {
    bottom: 30px;
    left: 30px;
  }
}
@media only screen and (max-width: 1024px) {
  .cards-wrap .card_custom .back .card__link {
    position: static;
  }
}
.cards-wrap .card_custom .back .card__link:hover {
  text-decoration: none;
}
.cards-wrap .card_custom .back .back-text__wrap {
  margin-top: 20px;
}
@media only screen and (max-width: 1024px) {
  .cards-wrap .card_custom .back .back-text__wrap {
    margin-top: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .cards-wrap .card_custom .back {
    transform: rotateY(0deg) !important;
    position: relative;
  }
}
.cards-wrap .card_custom .back h4 {
  margin-bottom: 15px;
}
.cards-wrap .card_custom:hover .front {
  transform: rotateY(180deg);
}
.cards-wrap .card_custom:hover .back {
  transform: rotateY(360deg);
}
.preloader-wrapper {
  height: 64px;
  margin-left: 80px;
  display: none;
}
.preloader-wrapper svg {
  height: 100%;
}
.form-wrp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form-wrp .form-group {
  width: 100%;
}
.form-wrp .form-group.form-group_half {
  width: 47%;
}
@media only screen and (max-width: 991px) {
  .form-wrp .form-group.form-group_half {
    width: 100%;
  }
}
.form-wrp label {
  display: block;
  margin-bottom: 6px;
  color: #000;
  font-size: 16px;
  line-height: 24px;
  font-family: 'proxima-nova-semibold';
  position: relative;
  top: 8px;
  transform: none !important;
}
.form-wrp textarea {
  resize: vertical;
  padding: 0 !important;
}
.form-wrp textarea {
  font-size: 20px;
  line-height: 24px;
  border-bottom: 2px solid #e0dfe0;
  width: 100%;
  height: 40px;
  min-height: auto;
  margin-top: 6px;
}
.form-wrp textarea:focus {
  border-bottom: 2px solid #e0dfe0 !important;
  box-shadow: none !important;
}
.form-wrp input {
  height: 40px !important;
  font-size: 20px;
  line-height: 35px;
  border-bottom: 2px solid #e0dfe0;
  width: 100%;
}
.form-wrp input.invalid {
  border-bottom: 2px solid #ee4323;
}
.form-wrp input.valid {
  border-bottom: 2px solid #00c088;
}
.form-wrp .input-field {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 30px;
}
@media only screen and (max-width: 991px) {
  .form-wrp .input-field {
    padding-bottom: 20px;
  }
}
.form-wrp .form-error {
  position: absolute;
  left: 0;
  bottom: 10px;
  color: #ee4323;
  font-size: 13px;
  line-height: 16px;
}
@media only screen and (max-width: 991px) {
  .form-wrp .form-error {
    bottom: 0;
  }
}
.form-wrp .checkboxes-policy-wrap label {
  position: relative;
}
.form-wrp .checkboxes-policy-wrap .checkbox-text {
  font-size: 16px;
  line-height: 28px;
  color: #959496;
  padding-left: 36px;
}
.form-wrp .checkboxes-policy-wrap a {
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
}
.form-wrp .checkboxes-policy-wrap .checkboxe-wrap {
  margin-bottom: 18px;
}
.form-wrp .checkboxes-policy-wrap .checkboxe-wrap .form-control.invalid + .checkbox-text:before {
  border: 2px solid #ee4323;
}
.form-wrp .checkboxes-policy-wrap .checkboxe-wrap .form-error {
  bottom: -17px;
  left: 35px;
}
[type="checkbox"]+span:not(.lever):before,
[type="checkbox"]:not(.filled-in)+span:not(.lever):after {
  width: 24px;
  height: 24px;
  border: 2px solid #e0dfe0;
}
[type="checkbox"]:checked+span:not(.lever):before {
  border-right: 2px solid #00c088;
  border-bottom: 2px solid #00c088;
}
.btn_green {
  width: auto;
  height: auto;
  padding: 16px 24px;
  background-color: #00c088;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_green:hover,
.btn_green:focus {
  background-color: #00c088;
}
.btn_green span {
  color: #fff;
  font-size: 24px;
  line-height: 35px;
  font-family: 'proxima-nova-semibold';
}
@media only screen and (max-width: 767px) {
  .btn_green span {
    font-size: 18px !important;
    line-height: 29px !important;
  }
}
@media only screen and (max-width: 767px) {
  .btn_green {
    width: 240px;
    height: 56px;
  }
}
.btn-wrap {
  margin-top: 27px;
  display: flex;
}
@media only screen and (max-width: 767px) {
  .btn-wrap {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
.btn-wrap .contact-form-bottom-button {
  width: 256px;
  height: 64px;
  background-color: #00c088;
  color: #fff;
  font-size: 24px;
  line-height: 35px;
  font-family: 'proxima-nova-semibold';
}
@media only screen and (max-width: 767px) {
  .btn-wrap .contact-form-bottom-button {
    font-size: 18px;
    line-height: 29px;
    width: 240px;
    height: 56px;
  }
}
.mobile-menu {
  width: 400px;
  padding: 30px 20px 80px;
  background-color: #000;
}
@media only screen and (max-width: 600px) {
  .mobile-menu {
    width: 100%;
  }
}
.mobile-menu .sidenav__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-menu .sidenav__header .cancel__btn:hover {
  cursor: pointer;
}
.mobile-menu .sidenav__body {
  padding: 0 20px;
}
.mobile-menu .links__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}
.mobile-menu .links__list .link-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.mobile-menu .links__list .link-wrap:before {
  display: none;
}
.mobile-menu .links__list .menu-link {
  color: #fff;
}
.mobile-menu .links__list .menu-link:after {
  transform: none;
  top: 11px;
}
.mobile-menu .collapsible {
  width: 100%;
}
.mobile-menu .collapsible .link-wrap {
  line-height: inherit;
  display: inline-block;
}
.mobile-menu .collapsible .link-wrap.active .menu-link_mobile {
  color: #00c088;
}
.mobile-menu .collapsible .link-wrap.active .menu-link_mobile .arrow-image {
  transform: rotate(180deg);
}
.mobile-menu .collapsible .link-wrap.active .menu-link_mobile .arrow-image path {
  fill: #00c088;
}
.mobile-menu .collapsible .link-wrap .menu-link_mobile {
  display: inline-flex;
  justify-content: flex-start;
}
.mobile-menu .collapsible .collapsible-header {
  padding-left: 0;
}
.mobile-menu .collapsible .collapsible-body {
  background-color: transparent;
  padding-left: 20px;
}
.mobile-menu .collapsible .collapsible-body .link {
  margin-top: 15px;
}
.mobile-menu .collapsible .collapsible-body .link a {
  color: #fff;
  text-decoration: none;
  line-height: 20px;
  font-size: 15px;
}
.mobile-menu .collapsible .collapsible-body .link a:hover {
  color: #00c088;
}
.custom-slider_white {
  margin-top: 65px;
  padding-bottom: 50px;
  width: calc(100% + 32px);
  margin-bottom: 40px;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .custom-slider_white {
    padding-bottom: 90px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .custom-slider_white {
    width: 100%;
  }
}
.custom-slider_white .slick-track {
  display: flex;
}
.custom-slider_white .prev-btn,
.custom-slider_white .next-btn {
  bottom: 0;
  border: 1px solid #b7b7b7 !important;
}
.custom-slider_white .prev-btn:hover,
.custom-slider_white .next-btn:hover {
  border: 1px solid #00c088 !important;
  background-color: #00c088 !important;
}
.custom-slider_white .prev-btn:hover svg g,
.custom-slider_white .next-btn:hover svg g {
  fill: #fff;
}
.custom-slider_white .prev-btn svg g,
.custom-slider_white .next-btn svg g {
  fill: #b7b7b7;
}
.custom-slider_white .slide {
  margin-right: 32px;
  text-decoration: none;
  height: inherit !important;
}
@media only screen and (max-width: 600px) {
  .custom-slider_white .slide {
    margin-right: 0;
  }
}
.slick-slider .prev-btn,
.slick-slider .next-btn {
  position: absolute;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  cursor: pointer;
  transition: all 0.2s ease;
}
.slick-slider .prev-btn:hover,
.slick-slider .next-btn:hover {
  background-color: rgba(255,255,255,0.2);
}
.slick-slider .prev-btn {
  bottom: -50px;
  left: 0;
  z-index: 2;
}
@media only screen and (max-width: 991px) {
  .slick-slider .prev-btn {
    bottom: -8px;
  }
}
.slick-slider .prev-btn svg {
  transform: rotate(180deg);
}
.slick-slider .prev-btn svg g {
  transition: all 0.2s ease;
}
.slick-slider .next-btn {
  left: 66px;
  bottom: -50px;
}
@media only screen and (max-width: 991px) {
  .slick-slider .next-btn {
    bottom: -8px;
  }
}
.slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.slick-dots li {
  position: relative;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid #686868;
  background-color: #fff;
  margin: 4px;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  z-index: 1;
}
.slick-dots li.slick-active {
  border: 1px solid #00c088;
  background-color: #00c088;
}
.slick-dots li button {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: transparent;
  z-index: 2;
}
.carousel .indicators .indicator-item {
  width: 10px;
  height: 10px;
  background-color: #fff;
  margin: 24px 5px;
  padding: 0;
}
.carousel .indicators .indicator-item:before {
  display: none;
}
.carousel .indicators .indicator-item.active {
  background-color: #00c088;
}
.pagination-wrap {
  position: absolute;
  left: 15px;
  top: -20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
@media only screen and (max-width: 991px) {
  .pagination-wrap {
    top: -40px;
  }
}
.pagination-wrap li {
  position: relative;
  padding-right: 34px;
  line-height: 21px;
}
.pagination-wrap li .pagination__link {
  text-decoration: none;
  transition: all 0.2s ease;
  line-height: 21px;
  font-size: 16px;
}
.pagination-wrap li .pagination__link span {
  color: #fff;
  font-size: 16px;
  line-height: 21px;
}
.pagination-wrap li:hover span {
  color: #00c088;
}
.pagination-wrap li:last-child span {
  color: #00c088;
}
.pagination-wrap li:not(:last-child):after {
  content: '/';
  position: absolute;
  right: 15px;
  top: 0;
  color: #fff;
}
.custom-tabs .tab__result-wrap {
  overflow: hidden;
}
.custom-tabs .tab__result {
  transform: translateX(-100%);
  transition: all 0.6s ease;
  height: 0;
  overflow: hidden;
}
.custom-tabs .tab__result.active {
  height: auto;
  transform: translateX(0);
  overflow: auto;
}
.copy-link__wrap {
  display: flex;
}
.copy-link__wrap .copy-block {
  margin-left: 8px;
  cursor: pointer;
  transition: 0.2s ease;
  position: relative;
  height: 20px;
  width: 16px;
}
.copy-link__wrap .copy-block:hover .tooltip__text {
  display: block;
  position: absolute;
  top: 24px;
  left: -60px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background-color: #000;
  padding: 5px 10px 6px;
  width: 145px;
}
@media only screen and (max-width: 991px) {
  .copy-link__wrap .copy-block:hover .tooltip__text {
    display: none !important;
  }
}
.copy-link__wrap .copy-block.active-copy .copy__text {
  display: block;
  position: absolute;
  top: 24px;
  left: -30px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background-color: #00c088;
  padding: 5px 10px 6px;
}
.copy-link__wrap .copy-block.active-copy .tooltip__text {
  display: none;
}
.copy-link__wrap .copy-block.active svg {
  transform: translate(-50%, -50%) scale(0.9, 0.9);
}
.copy-link__wrap .copy-block .tooltip__text,
.copy-link__wrap .copy-block .copy__text {
  display: none;
}
.copy-link__wrap .copy-block svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.copy-link__wrap a {
  color: #000;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
}
@media only screen and (max-width: 1200px) {
  .copy-link__wrap a {
    font-size: 16px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 991px) {
  .copy-link__wrap a {
    font-size: 14px;
    line-height: 18px;
  }
}
.more-link {
  text-decoration: none;
  position: relative;
  padding-bottom: 2px;
}
.more-link:after {
  content: '';
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #00c088;
  transition: all 0.2s ease;
}
.more-link:hover:after {
  width: 0;
}
.show-more__link {
  display: block;
  text-align: center;
  text-decoration: none;
  margin: 30px auto 0;
  padding: 14px 50px 15px;
  border: 1px solid #00c088;
  transition: all 0.2s ease;
}
.show-more__link.active {
  display: none;
}
.show-more__link:hover {
  background-color: #00c088;
}
.show-more__link:hover span {
  color: #fff;
}
.show-more__link:hover svg path {
  fill: #fff;
}
.show-more__link span {
  font-size: 18px;
  line-height: 18px;
  transition: all 0.2s ease;
  font-family: 'proxima-nova-semibold';
}
.show-more__link svg {
  margin-left: 8px;
  transform: scale(1.3);
}
.show-more__link svg path {
  transition: all 0.2s ease;
  fill: #00c088;
}
.modal {
  max-height: 90%;
  width: 70%;
  top: 5% !important;
}
@media only screen and (max-width: 991px) {
  .modal {
    width: 80%;
  }
}
@media only screen and (max-width: 600px) {
  .modal {
    width: 90%;
  }
}
.modal .modal-content {
  padding: 20px 40px 60px;
}
@media only screen and (max-width: 600px) {
  .modal .modal-content {
    padding: 20px 20px 60px;
  }
}
.modal .modal-content h2 {
  color: #00c088;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.modal .modal-content h3,
.modal .modal-content h4,
.modal .modal-content h5,
.modal .modal-content h6 {
  color: #000;
  margin-bottom: 20px;
  margin-top: 30px;
}
.modal .modal-content a {
  color: #00c088 !important;
  margin-left: 0 !important;
}
.modal .modal-cross-wrap {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  left: 0;
  top: 0;
  background-color: #fafafa;
  width: 100%;
  padding: 10px 0;
  z-index: 1004;
}
.modal .modal-cross-wrap .cross-link {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
}
.modal .modal-cross-wrap .cross-link:hover .cross-icon path {
  fill: #00c088;
}
.modal .modal-cross-wrap .cross-link .cross-icon path {
  fill: #686868;
}
.custom-top-scroll {
  position: fixed;
  top: 0;
  left: 0;
  height: 6px;
  background-color: #00c088;
  transition: all 0.2s ease;
  z-index: 1000;
}
#tidio-chat-iframe {
  max-height: calc(100vh - 70px) !important;
  margin-bottom: 60px !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
.enter-y {
  transform: translateY(50px) rotate(2deg);
  transition: all 1.2s cubic-bezier(0.37, 0.01, 0, 0.98);
  opacity: 0;
}
.enter {
  transition: all 1.2s cubic-bezier(0.37, 0.01, 0, 0.98);
  opacity: 0;
}
.seen .enter {
  opacity: 1;
  transition-delay: 0.3s !important;
}
.seen .enter-y {
  opacity: 1;
  transform: translate(0) scale(1) rotate(0);
}
.seen .delay-2 {
  transition-delay: 0.1s !important;
}
.seen .delay-3 {
  transition-delay: 0.15s !important;
}
.seen .delay-4 {
  transition-delay: 0.2s !important;
}
.seen .delay-5 {
  transition-delay: 0.25s !important;
}
.seen .delay-6 {
  transition-delay: 0.3s !important;
}
.seen .delay-7 {
  transition-delay: 0.35s !important;
}
.seen .delay-8 {
  transition-delay: 0.4s !important;
}
.seen .delay-9 {
  transition-delay: 0.45s !important;
}
.menu {
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #000;
  box-shadow: none;
  padding: 18px 0;
  z-index: 900;
}
@media only screen and (max-width: 991px) {
  .menu {
    padding: 15px 0;
  }
}
.menu .container {
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 100%;
}
.menu .menu__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  line-height: normal;
  position: relative;
}
.menu .brand-logo {
  line-height: 0;
  position: relative;
  left: 0;
  transform: none;
}
.menu .menu__links-wrap {
  display: flex;
}
.menu .menu__links-wrap .mobile-menu__btn {
  height: auto;
  line-height: inherit;
  margin: 0;
  cursor: pointer;
}
.menu .menu__links-wrap .mobile-menu__btn .open-menu__icon-block {
  height: 18px;
  width: 26px;
  position: relative;
  cursor: pointer;
}
.menu .menu__links-wrap .mobile-menu__btn .open-menu__icon-block .line {
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
}
.menu .menu__links-wrap .mobile-menu__btn .open-menu__icon-block .line:nth-child(1) {
  left: 0;
  top: 0;
}
.menu .menu__links-wrap .mobile-menu__btn .open-menu__icon-block .line:nth-child(2) {
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.menu .menu__links-wrap .mobile-menu__btn .open-menu__icon-block .line:nth-child(3) {
  left: 0;
  bottom: 0;
}
.contact-link_menu {
  border: 2px solid #00c088;
  transition: all 0.2s ease !important;
}
.contact-link_menu:hover {
  background-color: #00c088;
  transition: all 0.2s ease !important;
}
.contact-link_menu:hover span {
  color: #fff;
}
.menu-link_mobile {
  font-size: 18px;
  line-height: 24px;
  padding-left: 0 !important;
}
.menu-link_mobile.contact-link_menu {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.menu-link_mobile.dropdown-link .arrow-image {
  top: 13px !important;
}
.search-icon-link {
  display: flex !important;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
  height: auto !important;
  line-height: inherit !important;
  margin: 0 !important;
}
@media only screen and (max-width: 1200px) {
  .search-icon-link {
    padding: 0 24px;
  }
}
.search-icon-link path {
  transition: all 0.2s ease;
}
.search-icon-link:hover path {
  fill: #00c088;
}
.menu-link {
  padding: 8px 24px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}
.menu-link span {
  font-size: 18px;
}
@media only screen and (max-width: 1190px) {
  .menu-link:not(.menu-link_mobile) {
    display: none;
  }
}
.menu-link.dropdown-link {
  padding-right: 24px;
}
.menu-link.dropdown-link .arrow-image {
  position: absolute;
  right: 8px;
  top: 23px;
}
.menu-link.dropdown-link .arrow-image path {
  transition: color 0.2s ease;
}
.menu-link:first-child {
  margin-left: 0;
}
.menu-link:last-child {
  margin-right: 0;
}
.desktop-menu .menu-link {
  transition: color 0.2s ease;
}
.desktop-menu .menu-link.active {
  color: #00c088;
}
.desktop-menu .menu-link.active .arrow-image {
  transform: rotate(180deg);
}
.desktop-menu .menu-link.active .arrow-image path {
  fill: #00c088;
}
.desktop-menu .menu-link:hover {
  color: #00c088;
}
.desktop-menu .menu-link:hover .arrow-image {
  transition: all 0.2s ease;
}
.desktop-menu .menu-link:hover .arrow-image path {
  fill: #00c088;
}
.dropdown-menu {
  display: none;
  position: fixed;
  left: 0;
  top: 82px;
  width: 100vw;
  padding: 32px 0;
  background-color: rgba(0,0,0,0.9);
}
.dropdown-menu.active {
  display: block;
}
.dropdown-menu .container {
  max-width: 1380px;
}
.dropdown-menu .dropdown-menu__content {
  display: flex;
}
@media only screen and (min-width: 1201px) {
  .dropdown-menu .dropdown-menu__content--half .links-wrap,
  .dropdown-menu .dropdown-menu__content--half justify-content space-between,
  .dropdown-menu .dropdown-menu__content--half .link {
    width: 48%;
  }
  .dropdown-menu .dropdown-menu__content--col .links-wrap,
  .dropdown-menu .dropdown-menu__content--col justify-content space-between,
  .dropdown-menu .dropdown-menu__content--col .links-col {
    width: 48%;
  }
}
@media only screen and (min-width: 1201px) {
  .dropdown-menu .dropdown-menu__content--col .links-wrap a,
  .dropdown-menu .dropdown-menu__content--col .link a {
    font-size: 15px;
  }
}
@media only screen and (min-width: 1260px) {
  .dropdown-menu .dropdown-menu__content--col .links-wrap a,
  .dropdown-menu .dropdown-menu__content--col .link a {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1400px) {
  .dropdown-menu .dropdown-menu__content--col .links-wrap a,
  .dropdown-menu .dropdown-menu__content--col .link a {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1201px) {
  .dropdown-menu .dropdown-menu__content--half .links-wrap a,
  .dropdown-menu .dropdown-menu__content--half .link a {
    font-size: 15px;
  }
}
@media only screen and (min-width: 1260px) {
  .dropdown-menu .dropdown-menu__content--half .links-wrap a,
  .dropdown-menu .dropdown-menu__content--half .link a {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1400px) {
  .dropdown-menu .dropdown-menu__content--half .links-wrap a,
  .dropdown-menu .dropdown-menu__content--half .link a {
    font-size: 18px;
  }
}
.dropdown-menu .intro-block {
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px;
  display: none;
  align-items: center;
  justify-content: center;
}
.dropdown-menu .intro-block.active {
  display: flex;
}
.dropdown-menu .intro-block p {
  color: #fff;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
}
.dropdown-menu .links-wrap {
  display: flex;
  flex-wrap: wrap;
}
.dropdown-menu .links-wrap:before {
  display: none;
}
.dropdown-menu .links-wrap .link {
  width: 100%;
  margin-bottom: 8px;
}
.dropdown-menu .links-wrap .link a {
  text-decoration: none;
}
.dropdown-menu .links-wrap .link a:hover {
  color: #00c088;
}
.menu.not-top .dropdown-menu {
  top: 82px;
}
@media only screen and (max-width: 1190px) {
  .menu a.sidenav-trigger {
    display: block;
  }
}
.search-sidenav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #2c2e31;
  padding-top: 68px;
  padding-bottom: 38px;
  height: auto !important;
  transform: translateY(-150%);
}
.search-sidenav .form_search {
  position: relative;
  max-width: 890px;
}
.search-sidenav .input_search {
  border-bottom: 2px solid #474747;
  padding: 8px 0 8px 45px;
  position: relative;
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  height: auto !important;
}
@media only screen and (max-width: 600px) {
  .search-sidenav .input_search {
    font-size: 24px;
    line-height: 32px;
  }
}
.search-sidenav .input_search::placeholder {
  color: #6a6a6a;
}
.search-sidenav .submitButton {
  background-color: transparent;
  position: absolute;
  padding: 8px 13px;
  top: 12px;
  left: 0;
}
@media only screen and (max-width: 600px) {
  .search-sidenav .submitButton {
    top: 9px;
  }
}
.search-sidenav .submitButton svg path {
  transition: all 0.2s ease;
}
.search-sidenav .submitButton:hover svg path {
  fill: #fff;
}
.dropdown-menu.dropdown-menu--lang {
  max-width: 56px;
  left: auto;
  right: 176px;
  padding-top: 10px;
  padding-bottom: 10px;
  top: 60px;
  background-color: #2c2e30;
}
.dropdown-menu.dropdown-menu--lang .container {
  padding-left: 14px;
  padding-right: 14px;
}
.menu + .s-about .container {
  padding-top: 180px;
}
@media only screen and (max-width: 991px) {
  .menu + .s-about .container {
    padding-top: 150px;
  }
}
.menu + .s-about .container .content-wrap {
  position: relative;
}
.menu + .s-about .container .content-wrap .pagination-wrap {
  top: -60px;
  display: flex;
}
.s-about {
  transition: all 0.2s ease;
}
.s-about[data-country='usa'] {
  background: url("../../assets/images/img/s-about-america.jpg") center no-repeat;
  background-size: cover;
}
.s-about[data-country='norv'] {
  background: url("../../assets/images/img/s-about-norv.jpg") center no-repeat;
  background-size: cover;
}
.s-about[data-country='fi'] {
  background: url("../../assets/images/img/s-about-finland.jpg") center no-repeat;
  background-size: cover;
}
.s-about[data-country='ar'] {
  background: url("../../assets/images/img/s-about-argentina.jpg") center no-repeat;
  background-size: cover;
}
.s-about[data-country='by'] {
  background: url("../../assets/images/img/s-about-minsk.jpg") center no-repeat;
  background-size: cover;
}
.s-about[data-country='pl'] {
  background: url("../../assets/images/img/s-about-poland.jpg") center no-repeat;
  background-size: cover;
}
.s-about[data-country='uk'] {
  background: url("../../assets/images/img/s-about-ukraine.jpg") center no-repeat;
  background-size: cover;
}
.s-about[data-country='md'] {
  background: url("../../assets/images/img/s-about-moldova.jpg") center no-repeat;
  background-size: cover;
}
.s-about[data-country='ge'] {
  background: url("../../assets/images/img/s-about-georgia.jpg") center no-repeat;
  background-size: cover;
}
.s-about[data-country='tyrk'] {
  background: url("../../assets/images/img/s-about-turkey.jpg") center no-repeat;
  background-size: cover;
}
.s-about[data-country='canada'] {
  background: url("../../assets/images/img/locations_canada.jpg") center no-repeat;
  background-size: cover;
}
.s-about[data-country='irlnd'] {
  background: url("../../assets/images/img/locations_ireland.jpg") center no-repeat;
  background-size: cover;
}
.s-about.s-about_main {
  border-bottom: 1px solid #ddd;
}
.s-about.s-about_main .s-about_main__content {
  display: flex;
}
.s-about.s-about_main .content-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.s-about.s-about_main .content-image .s-about-main__image {
  max-height: 280px;
}
@media only screen and (max-width: 600px) {
  .s-about.s-about_main .content-image {
    width: 100%;
  }
  .s-about.s-about_main .content-image .s-about-main__image {
    max-height: 200px;
  }
}
.s-about.s-about_main .preheader {
  color: #686868;
}
.s-about .pagination-wrap {
  display: none;
}
.s-about .preheader {
  color: #fff;
}
.s-about .preheader {
  max-width: 740px;
}
.s-about .s-about__tabs-wrap {
  display: flex;
  margin-top: 40px;
}
@media only screen and (max-width: 767px) {
  .s-about .s-about__tabs-wrap {
    display: none;
  }
}
.s-about .s-about__tabs-wrap .tabs__results-wrap {
  width: 45%;
}
@media only screen and (max-width: 991px) {
  .s-about .s-about__tabs-wrap .tabs__results-wrap {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .s-about .s-about__tabs-wrap .tabs__results-wrap {
    width: calc(100vw - 60px);
  }
}
.s-about .s-about__tabs-wrap .tabs__results-wrap .tab__result {
  display: none;
  position: relative;
  padding: 50px 60px 40px;
  background-color: rgba(0,0,0,0.7);
  height: 100%;
}
@media only screen and (max-width: 1200px) {
  .s-about .s-about__tabs-wrap .tabs__results-wrap .tab__result {
    padding: 70px 50px 20px;
  }
}
@media only screen and (max-width: 991px) {
  .s-about .s-about__tabs-wrap .tabs__results-wrap .tab__result {
    padding: 50px 40px;
  }
}
.s-about .s-about__tabs-wrap .tabs__results-wrap .tab__result.active {
  display: block;
}
.s-about .s-about__tabs-wrap .tabs__links-wrap {
  width: 55%;
}
@media only screen and (max-width: 991px) {
  .s-about .s-about__tabs-wrap .tabs__links-wrap {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .s-about .s-about__tabs-wrap .tabs__links-wrap {
    display: none;
  }
}
.s-about .s-about__tabs-wrap .tabs__links-wrap .tab__link {
  display: flex;
  width: 100%;
  padding: 32px 24px;
  border-bottom: 1px solid #ddd;
  transition: 0.2s ease;
  background-color: rgba(255,255,255,0.9);
  cursor: pointer;
}
.s-about .s-about__tabs-wrap .tabs__links-wrap .tab__link:last-child {
  border-bottom: none;
}
.s-about .s-about__tabs-wrap .tabs__links-wrap .tab__link.active,
.s-about .s-about__tabs-wrap .tabs__links-wrap .tab__link:hover {
  background-color: rgba(0,192,136,0.9);
}
.s-about .s-about__tabs-wrap .tabs__links-wrap .tab__link.active p,
.s-about .s-about__tabs-wrap .tabs__links-wrap .tab__link:hover p {
  color: #fff;
}
.s-about .s-about__tabs-wrap .tabs__links-wrap .tab__link .flag__icon {
  margin-right: 11px;
  width: 42px;
  height: 26px;
  margin-top: 2px;
}
.s-about .s-about__tabs-wrap .tabs__links-wrap .tab__link p {
  margin: 0;
  font-size: 22px;
  line-height: 32px;
  color: #000;
  font-family: 'proxima-nova-bold';
  transition: 0.2s ease;
  text-align: left;
}
@media only screen and (max-width: 1400px) {
  .s-about .s-about__tabs-wrap .tabs__links-wrap .tab__link p {
    font-size: 18px;
    line-height: 24px;
  }
}
.s-about .phones__block {
  padding-left: 45px;
  position: relative;
  max-width: 320px;
  margin-bottom: 16px;
}
.s-about .phones__block:before {
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/assets/images/svg/telephone-icon.svg") center no-repeat;
  height: 26px;
  width: 23px;
  position: absolute;
  left: 0;
  top: 2px;
}
.s-about .phones__block span,
.s-about .phones__block a {
  text-decoration: none;
  color: #fff;
  font-size: 22px;
  line-height: 30px;
  display: block;
  margin: 4px 0;
}
@media only screen and (max-width: 767px) {
  .s-about .phones__block span,
  .s-about .phones__block a {
    font-size: 18px !important;
    line-height: 26px;
  }
}
.s-about .phones__block a {
  margin-bottom: 14px;
}
.s-about .address__block {
  padding-left: 45px;
  position: relative;
  max-width: 320px;
  margin-bottom: 16px;
}
.s-about .address__block:before {
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/assets/images/svg/location-icon.svg") center no-repeat;
  height: 26px;
  width: 23px;
  position: absolute;
  left: 0;
  top: 4px;
}
.s-about .address__block p {
  color: #fff;
  font-size: 22px;
  line-height: 30px;
}
@media only screen and (max-width: 767px) {
  .s-about .address__block p {
    font-size: 18px;
    line-height: 26px;
  }
}
@media only screen and (max-width: 991px) {
  .s-about .address__block p {
    font-size: 20px;
    line-height: 28px;
  }
}
.s-about .site__block {
  padding-left: 45px;
  position: relative;
  max-width: 320px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}
.s-about .site__block:before {
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/assets/images/svg/location-web.svg") center no-repeat;
  height: 26px;
  width: 23px;
  position: absolute;
  left: 0;
  top: 4px;
}
.s-about .site__block a {
  color: #00c088 !important;
}
.s-about .quarantine-block p {
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .tabs__results-wrap_mobile {
    display: none;
  }
}
.tabs__results-wrap_mobile {
  min-height: 520px !important;
}
.tabs__results-wrap_mobile .tab__result {
  border: 1px solid #5f5f5f;
  padding: 40px;
  background-color: rgba(0,0,0,0.7);
}
@media only screen and (max-width: 400px) {
  .tabs__results-wrap_mobile .tab__result {
    padding: 40px 30px;
  }
}
.tabs__results-wrap_mobile .tab__result .descr-block {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
.tabs__results-wrap_mobile .tab__result .descr-block .flag__icon {
  margin-right: 15px;
  width: 30px;
  height: 20px;
  margin-top: 5px;
}
.tabs__results-wrap_mobile .tab__result .descr-block .flag__icon.flag__icon_usa {
  background: url("/assets/images/img/flag-america.png") center no-repeat;
  background-size: cover;
}
.tabs__results-wrap_mobile .tab__result .descr-block .flag__icon.flag__icon_norv {
  background: url("/assets/images/img/flag-norvegia.png") center no-repeat;
  background-size: cover;
}
.tabs__results-wrap_mobile .tab__result .descr-block .flag__icon.flag__icon_fi {
  background: url("/assets/images/img/flag-finlandia.png") center no-repeat;
  background-size: cover;
}
.tabs__results-wrap_mobile .tab__result .descr-block .flag__icon.flag__icon_ar {
  background: url("/assets/images/img/flag-argentina.png") center no-repeat;
  background-size: cover;
}
.tabs__results-wrap_mobile .tab__result .descr-block .flag__icon.flag__icon_by {
  background: url("/assets/images/img/flag-belarus.png") center no-repeat;
  background-size: cover;
}
.tabs__results-wrap_mobile .tab__result .descr-block p {
  width: calc(100% - 57px);
}
.tabs__results-wrap_mobile .tab__result .phones__block {
  padding-left: 45px;
  position: relative;
  max-width: 320px;
  margin-bottom: 16px;
}
.tabs__results-wrap_mobile .tab__result .phones__block:before {
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/assets/images/svg/telephone-icon.svg") center no-repeat;
  height: 26px;
  width: 20px;
  position: absolute;
  left: 4px;
  top: 4px;
}
.tabs__results-wrap_mobile .tab__result .address__block {
  padding-left: 45px;
  position: relative;
  max-width: 320px;
  margin-bottom: 16px;
}
.tabs__results-wrap_mobile .tab__result .address__block:before {
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/assets/images/svg/location-icon.svg") center no-repeat;
  height: 26px;
  width: 20px;
  position: absolute;
  left: 5px;
  top: 4px;
}
.tabs__results-wrap_mobile .tab__result a,
.tabs__results-wrap_mobile .tab__result p {
  font-size: 18px !important;
  line-height: 24px !important;
  color: #fff;
  text-decoration: none;
  display: block;
  margin: 0;
}
@media only screen and (max-width: 400px) {
  .tabs__results-wrap_mobile .tab__result a,
  .tabs__results-wrap_mobile .tab__result p {
    font-size: 16px !important;
    line-height: 22px !important;
  }
}
.s-contact {
  border-top: 1px solid #ddd;
}
.s-contact .section-description,
.s-contact h2 {
  color: #000;
}
.s-contact .addition-blocks-wrap {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .s-contact .addition-blocks-wrap {
    flex-wrap: wrap;
  }
}
.s-contact .addition-blocks-wrap .addition-block {
  display: flex;
  align-items: center;
  margin-right: 120px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .s-contact .addition-blocks-wrap .addition-block {
    margin-right: 0;
    width: 100%;
  }
}
.s-contact .addition-blocks-wrap .addition-block:last-child {
  margin-right: 0;
}
.s-contact .addition-blocks-wrap .addition-block img {
  margin-right: 16px;
}
.s-contact .addition-blocks-wrap .addition-block p {
  font-family: 'proxima-nova-semibold';
  margin: 0;
}
.s-contact .form-bottom {
  padding: 56px;
  border: 1px solid #e0dfe0;
}
@media only screen and (max-width: 600px) {
  .s-contact .form-bottom {
    padding: 40px 0;
    border: none;
  }
}
.s-contact .form-content {
  padding-right: 0;
}
@media only screen and (max-width: 992px) {
  .s-contact .form-content {
    padding-right: 0.75rem;
    width: 100% !important;
    order: 2;
  }
}
.s-contact .form-content__wrap {
  display: flex;
  width: 100%;
  align-items: stretch;
}
@media only screen and (max-width: 992px) {
  .s-contact .form-content__wrap {
    flex-wrap: wrap;
  }
}
.s-contact .description-wrap {
  max-width: 350px;
}
@media only screen and (max-width: 992px) {
  .s-contact .description-wrap {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .s-contact .description-wrap p {
    width: 100%;
    text-align: center;
  }
}
.s-contact .contact-description-person {
  padding: 56px;
  background: #f9fafb;
  border: 1px solid #e0dfe0;
  border-left: none;
  height: 100%;
}
@media only screen and (max-width: 992px) {
  .s-contact .contact-description-person {
    border-left: 1px solid #e0dfe0;
    border-bottom: none;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact .contact-description-person {
    padding: 40px 20px;
    border-bottom: 1px solid #e0dfe0;
  }
}
.s-contact .contact-description-person .contact-description__photo {
  width: 200px;
  max-width: 100%;
  margin-bottom: 32px;
}
.s-contact .contact-description-person .contact-description__name {
  margin: 0;
  font-size: 22px;
  line-height: 32px;
  color: #2d2b2f;
  font-weight: 600;
}
.s-contact .contact-description-person .contact-description__position {
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 26px;
  color: #2d2b2f;
}
.s-contact .contact-description-person .contact-description__mail {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 26px;
  color: #29b673;
  text-decoration: none;
}
.s-contact .contact-description-person .contact-description__phone {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 26px;
  color: #2d2b2f;
  width: 100%;
}
@media only screen and (max-width: 992px) {
  .s-contact .contact-description-person .contact-description__phone {
    text-align: center;
  }
}
.s-contact .contact-description-person .contact-description__address {
  font-size: 18px;
  line-height: 26px;
  color: #2d2b2f;
}
.s-contact .contact-description-person .contact-description__address strong {
  font-weight: 600;
}
.s-contact .description-content-person {
  padding-left: 0;
}
@media only screen and (max-width: 992px) {
  .s-contact .description-content-person {
    padding-left: 0.75rem;
    width: 100% !important;
    order: 1;
  }
}
.s-contact .contact-description {
  position: relative;
  margin-top: 102px;
}
@media only screen and (max-width: 767px) {
  .s-contact .contact-description {
    margin-top: 28px;
  }
}
.s-contact .contact-description:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
  position: absolute;
  left: 64px;
  bottom: 64px;
  z-index: 1;
}
@media only screen and (max-width: 1700px) {
  .s-contact .contact-description:after {
    left: 30px;
    bottom: 30px;
  }
}
.s-contact .contact-description h5 {
  margin-bottom: 10px;
  width: 100%;
}
.s-contact .contact-description .description-wrap {
  border: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 50px 60px;
  z-index: 2;
  position: relative;
  background-color: #fff;
}
@media only screen and (max-width: 1700px) {
  .s-contact .contact-description .description-wrap {
    padding: 50px 50px;
  }
}
@media only screen and (max-width: 991px) {
  .s-contact .contact-description .description-wrap {
    padding: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .s-contact .contact-description .description-wrap {
    justify-content: flex-start;
  }
}
.s-contact .contact-description .description-wrap .description-block {
  margin-top: 30px;
}
@media only screen and (max-width: 991px) {
  .s-contact .contact-description .description-wrap .description-block {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .s-contact .contact-description .description-wrap .description-block {
    padding-right: 20px;
  }
  .s-contact .contact-description .description-wrap .description-block:last-child {
    padding-right: 0;
  }
}
.s-contact .contact-description .description-wrap .description-block .header {
  font-family: 'proxima-nova-semibold';
  color: #000;
  margin-bottom: 5px;
}
@media only screen and (max-width: 1200px) {
  .s-contact .contact-description .description-wrap .description-block .header {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .form-content,
  .description-content {
    width: 100% !important;
  }
  .s-contact .contact-description:after {
    display: none;
  }
}
.s-contact .s-contact-customers__customers-slide {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.s-contact .s-contact-customers__customers-photo {
  width: 234px;
  height: 234px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 17px solid #d3d4d5;
}
@media only screen and (max-width: 1200px) {
  .s-contact .s-contact-customers__customers-photo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 400px) {
  .s-contact .s-contact-customers__customers-photo {
    width: 190px;
    height: 190px;
  }
}
.s-contact .s-contact-customers__customers-photo img {
  max-width: 100%;
}
.s-contact .s-contact-customers__customers-slider {
  border: 1px solid #00c088;
  box-sizing: border-box;
  margin-left: 60px;
}
.s-contact .s-contact-customers__customers-slider:after {
  content: '';
  display: block;
  position: absolute;
  top: -12px;
  right: 80px;
  width: 55px;
  height: 22px;
  background: url("../../assets/images/img/dots-new.png") center no-repeat;
  background-size: 32px;
  background-color: #f8f9fb;
}
@media only screen and (max-width: 992px) {
  .s-contact .s-contact-customers__customers-slider {
    margin-left: 0;
  }
}
.s-contact .s-contact-customers__customers-slide {
  padding: 50px;
}
@media only screen and (max-width: 500px) {
  .s-contact .s-contact-customers__customers-slide {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.s-contact .s-contact-customers__customers-content {
  width: calc(100% - 234px);
  padding-left: 16px;
}
@media only screen and (max-width: 1200px) {
  .s-contact .s-contact-customers__customers-content {
    width: 100%;
  }
}
.s-contact .s-contact-customers__customers-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 800;
  color: #2d2b2f;
  margin-bottom: 8px;
}
.s-contact .s-contact-customers__customers-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #2d2b2f;
  margin-bottom: 0;
}
.s-contact .s-contact-customers__customers-about {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 16px;
}
.s-contact .s-contact-customers__customers-name {
  font-size: 16px;
  line-height: 20px;
  color: #2d2b2f;
  margin-bottom: 4px;
}
.s-contact .s-contact-customers__customers-position {
  font-size: 16px;
  line-height: 20px;
  color: #2d2b2f;
  margin-bottom: 0;
}
.s-contact .s-contact-customers__customers-logo {
  max-width: 90px;
}
.s-contact .s-contact-customers__customers-logo img {
  max-width: 100%;
}
.s-contact-customers {
  background-color: #f8f9fb;
}
.s-contact-customers .slick-slider .prev-btn,
.s-contact-customers .slick-slider .next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 60px;
  width: 24px;
  background-color: #f8f9fb;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}
.s-contact-customers .slick-slider .prev-btn img,
.s-contact-customers .slick-slider .next-btn img {
  height: 46px;
}
.s-contact-customers .slick-slider .prev-btn {
  left: -14px;
}
.s-contact-customers .slick-slider .next-btn {
  right: -14px;
  left: auto;
}
@media only screen and (max-width: 767px) {
  .s-contact-customers .s-contact-customers__customers {
    width: 100% !important;
    margin-top: 20px;
  }
}
.s-customers {
  background: url("/assets/images/img/s-customers_bg3.jpg") center no-repeat;
  background-size: cover;
}
.s-customers .s-customers__slider-wrap {
  position: relative;
}
.s-customers .more__link {
  position: absolute;
  bottom: 10px;
  left: 14px;
  z-index: 2;
}
@media only screen and (max-width: 991px) {
  .s-customers .more__link {
    bottom: 80px;
  }
}
.s-customers .s-customers__slider {
  position: relative;
  padding-bottom: 50px;
  max-width: 650px;
}
@media only screen and (max-width: 991px) {
  .s-customers .s-customers__slider {
    max-width: inherit;
    padding-bottom: 80px;
    margin-bottom: 35px;
  }
}
.s-customers .s-customers__slider .review__block .review__text {
  color: #fff;
  font-style: italic;
  margin-bottom: 25px;
}
.s-customers .s-customers__slider .review__block .review__position,
.s-customers .s-customers__slider .review__block .review__name {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 24px;
  color: #b7b7b7;
}
.s-customers .s-customers__logos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  grid-auto-rows: 88px;
  grid-gap: 20px;
}
@media only screen and (max-width: 500px) {
  .s-customers .s-customers__logos {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 80px;
    grid-gap: 15px;
  }
}
.s-customers .s-customers__logos .logo-block {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
  padding: 15px;
  transition: all 0.2s ease;
}
.s-customers .s-customers__logos .logo-block:hover {
  border: 1px solid #00c088;
  background-color: #00c088;
}
.s-customers .s-customers__logos .logo-block:nth-child(1) {
  grid-column-start: 3;
}
.s-customers .s-customers__logos .logo-block:nth-child(2) {
  grid-column-start: 3;
}
.s-customers .s-customers__logos .logo-block:nth-child(3) {
  grid-column-start: 2;
}
@media only screen and (max-width: 991px) {
  .s-customers .s-customers__logos .logo-block {
    grid-column-start: auto !important;
  }
}
.s-customers .s-customers__logos .logo-block img {
  max-height: 100%;
  max-width: 100%;
  margin: 0;
}
.s-development .section-description {
  color: #fff;
}
.s-development .cards-wrap .card_custom:nth-child(n + 4) {
  display: none;
}
.show-more_active .card_custom {
  display: block !important;
}
.s-footer {
  padding: 70px 0 50px;
  background: url("/assets/images/img/bg_black.jpg");
  background-color: #000 !important;
}
.s-footer .s-foote__top-block {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block {
    flex-wrap: wrap;
  }
}
.s-footer .s-foote__top-block .brand-logo {
  margin-bottom: 16px;
}
.s-footer .s-foote__top-block .brand__preheader {
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}
.s-footer .s-foote__top-block .articles-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  max-width: 440px;
  padding-right: 30px;
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block .articles-wrap {
    width: 100%;
    max-width: inherit;
    justify-content: flex-start;
  }
}
.s-footer .s-foote__top-block .articles-wrap .article {
  text-decoration: none;
  color: #fff;
  width: 100%;
}
.s-footer .s-foote__top-block .articles-wrap .article .article__text {
  max-width: 400px;
  font-size: 18px;
  line-height: 26px;
  margin: 16px 0;
  display: block;
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block .articles-wrap .article .article__text {
    font-size: 16px;
    line-height: 24px;
  }
}
.s-footer .s-foote__top-block .articles-wrap .article .article__date {
  font-size: 14px;
  line-height: 21px;
  opacity: 0.4;
}
.s-footer .s-foote__top-block .menu_bottom {
  width: calc(100% - 440px);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  margin: 0 0 12px;
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block .menu_bottom {
    width: 100%;
    justify-content: flex-start;
  }
}
.s-footer .s-foote__top-block .menu_bottom .links-wrap_social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  top: 0;
  right: 0;
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block .menu_bottom .links-wrap_social {
    justify-content: flex-start;
  }
}
.s-footer .s-foote__top-block .menu_bottom .links-wrap_social .link {
  margin: 0 0 16px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block .menu_bottom .links-wrap_social .link {
    margin: 16px 20px 16px 0;
  }
}
.s-footer .s-foote__top-block .menu_bottom .links-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block .menu_bottom .links-wrap {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.s-footer .s-foote__top-block .menu_bottom .links-wrap .link {
  margin: 16px 40px 16px 0;
  text-decoration: none;
  color: #fff;
}
.s-footer .s-foote__top-block .menu_bottom .links-wrap .link:last-child {
  margin-right: 0;
}
.s-footer .s-foote__top-block .menu_bottom .links-wrap .link:hover {
  color: #00c088;
}
@media only screen and (max-width: 991px) {
  .s-footer .s-foote__top-block .menu_bottom .links-wrap .link {
    margin: 16px 16px 16px 0;
  }
}
@media only screen and (max-width: 860px) {
  .s-footer .s-foote__top-block .menu_bottom .links-wrap .link {
    margin: 5px 16px 5px 0;
  }
}
.copyright {
  background-color: #f7f7f7;
  max-width: 100vw;
}
.copyright .copyright-logos-wrap {
  display: flex;
  justify-content: space-between;
  width: calc(100% + 40px);
  margin-left: -20px;
  padding: 30px 0;
}
@media only screen and (max-width: 1400px) {
  .copyright .copyright-logos-wrap {
    overflow: scroll;
    justify-content: flex-start;
    width: 100%;
    margin-left: 0;
  }
}
.copyright .copyright-logos-wrap .copyright-logo {
  height: 96px;
  margin: 20px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 1130px) {
  .copyright .copyright-logos-wrap .copyright-logo {
    margin: 20px 30px;
  }
}
.copyright .copyright-logos-wrap .copyright-logo img {
  height: 100%;
  opacity: 0.3;
  filter: grayscale(100%);
  transition: all 0.2s ease;
}
.copyright .copyright-logos-wrap .copyright-logo:hover img {
  opacity: 1;
  filter: grayscale(0);
}
.copyright .copyright-wrap {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #e6e6e6;
}
@media only screen and (max-width: 860px) {
  .copyright .copyright-wrap {
    flex-wrap: wrap;
  }
  .copyright .copyright-wrap .left-part {
    width: 100%;
    order: 2;
  }
  .copyright .copyright-wrap .rigth-part {
    order: 1;
  }
}
.copyright .copyright-wrap .copyright__text {
  font-size: 18px;
  line-height: 26px;
  margin: 16px 0;
  display: block;
  color: #888;
}
@media only screen and (max-width: 860px) {
  .copyright .copyright-wrap .copyright__text {
    font-size: 16px;
    line-height: 24px;
  }
}
.copyright .copyright-wrap .back-top__link,
.copyright .copyright-wrap a {
  cursor: pointer;
  text-decoration: none;
  color: #00c088;
  margin-left: 46px;
  font-family: 'proxima-nova-semibold';
}
.copyright .copyright-wrap .back-top__link:hover,
.copyright .copyright-wrap a:hover {
  color: #00c088;
}
@media only screen and (max-width: 992px) {
  .copyright .copyright-wrap .back-top__link,
  .copyright .copyright-wrap a {
    margin-left: 20px;
  }
}
@media only screen and (max-width: 860px) {
  .copyright .copyright-wrap .back-top__link,
  .copyright .copyright-wrap a {
    margin-left: 0;
    margin-right: 20px;
  }
}
.s-footer.short {
  display: none !important;
}
.copyright.short {
  background-color: #fff;
}
.copyright.short .copyright-logos-wrap {
  display: none !important;
}
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSlider {
  height: inherit;
}
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSlider:before {
  border: none;
  top: 0;
}
#CybotCookiebotDialog [type="checkbox"] {
  pointer-events: auto;
}
#CybotCookiebotDialog #CybotCookiebotDialogPoweredbyCybot {
  display: none !important;
}
.s-header {
  min-height: 820px;
  padding-top: 140px;
  padding-bottom: 75px;
  background-position: 100% 0;
  background: url("/assets/images/img/bg_black.jpg");
  background-size: cover;
  background-color: #000;
  position: relative;
  display: flex;
  overflow: hidden;
  z-index: 2;
}
.s-header.small {
  min-height: 600px;
}
@media only screen and (max-width: 600px) {
  .s-header.small {
    min-height: 100vh;
  }
}
.s-header .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.s-header .video-overlay {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8%;
}
@media only screen and (max-width: 991px) {
  .s-header .video-overlay {
    margin-right: 0;
  }
}
@media only screen and (max-width: 600px) {
  .s-header .video-overlay {
    width: 100%;
  }
}
.s-header .video-overlay .video_header {
  max-height: 600px;
  max-width: 100vw;
  box-shadow: 0 0 100px 50px #000;
  border-radius: 50%;
}
@media only screen and (max-width: 991px) {
  .s-header .video-overlay .video_header {
    max-height: 400px;
  }
}
.s-header .row {
  height: 100%;
}
.s-header .s-header__content-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}
@media only screen and (max-width: 991px) {
  .s-header {
    min-height: 600px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header {
    min-height: 100vh;
  }
}
.s-header .s-header__content {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  flex-wrap: wrap;
}
.s-header .s-header__content .btn_green {
  margin-top: 50px;
  width: auto;
  display: inline-flex;
  padding-left: 30px;
  padding-right: 30px;
  min-width: 256px;
}
.s-header .s-header__content .s-header__text-wrap {
  max-width: 855px;
}
@media only screen and (max-width: 600px) {
  .s-header .s-header__content .s-header__text-wrap {
    max-width: 100%;
  }
}
.s-header .s-header__content .s-header__text-wrap .s-header_article {
  font-size: 28px;
  line-height: 34px;
  font-style: italic;
  color: #cecece;
}
@media only screen and (max-width: 767px) {
  .s-header .s-header__content .s-header__text-wrap .s-header_article {
    font-size: 22px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 450px) {
  .s-header .s-header__content .s-header__text-wrap .s-header_article {
    font-size: 20px;
    line-height: 26px;
  }
}
.s-header .s-header__content .s-header__text-wrap h1 {
  margin: 30px 0;
}
.s-header .s-header__content .s-header__text-wrap .section-description {
  color: #fff;
}
.s-header .s-header__content .s-header__text-wrap.s-header__text-wrap_slider {
  max-width: inherit;
  width: 100%;
  display: none;
}
.s-header .s-header__content .s-header__text-wrap.s-header__text-wrap_slider .slick-list,
.s-header .s-header__content .s-header__text-wrap.s-header__text-wrap_slider .s-header__text-wrap_slide {
  max-width: 800px;
}
.s-header .s-header__tags-wrap {
  position: absolute;
  bottom: -45px;
}
@media only screen and (max-width: 991px) {
  .s-header .s-header__tags-wrap {
    position: relative;
    margin-top: 20px;
  }
}
.s-header .s-header__tags-wrap .s-header__tags-content {
  display: flex;
  flex-wrap: wrap;
}
.s-header .s-header__tags-wrap .s-header__tags-content .tag {
  padding: 10px 20px;
  background-color: #00c088;
  margin: 0 10px 10px 0;
}
@media only screen and (max-width: 991px) {
  .s-header .s-header__tags-wrap .s-header__tags-content .tag {
    padding: 8px 16px;
  }
}
@media only screen and (max-width: 767px) {
  .s-header .s-header__tags-wrap .s-header__tags-content .tag {
    padding: 6px 14px;
  }
}
.s-header .s-header__tags-wrap .s-header__tags-content .tag span {
  color: #fff;
  font-size: 20px;
}
@media only screen and (max-width: 991px) {
  .s-header .s-header__tags-wrap .s-header__tags-content .tag span {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .s-header .s-header__tags-wrap .s-header__tags-content .tag span {
    font-size: 16px;
  }
}
.s-header .s-header__description-block {
  position: relative;
}
.s-header .s-header__testimonial {
  position: absolute;
  right: -430px;
  top: 0;
  width: 360px;
}
@media only screen and (max-width: 1360px) {
  .s-header .s-header__testimonial {
    position: static;
  }
}
@media only screen and (max-width: 600px) {
  .s-header .s-header__testimonial {
    width: auto;
  }
  .s-header .s-header__testimonial img {
    max-width: 100%;
  }
}
.s-header .s-header__testimonial h5 {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}
@media only screen and (max-width: 1360px) {
  .s-header .s-header__testimonial h5 {
    position: static;
  }
}
.s-header .s-header__testimonial p {
  font-size: 16px;
  line-height: 26px;
}
.s-header .s-header__testimonial .subheader {
  margin-bottom: 8px;
  color: #fff;
  opacity: 0.5;
}
.s-header .s-header__testimonial .header-text {
  margin-top: 32px;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
}
.s-header .s-header__testimonial .text {
  color: #fff;
}
.s-header .s-header__testimonial svg {
  max-width: 100%;
}
.s-header .s-header__overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.72;
  height: 100%;
  width: 100%;
}
.s-header.s-header-slider {
  padding-top: 0;
  padding-bottom: 0;
}
.s-header.s-header-slider .s-header-slider__slider {
  width: 100vw;
}
.s-header.s-header-slider .s-header-slider__slide {
  padding-top: 200px;
  padding-bottom: 200px;
  min-height: 820px;
}
@media only screen and (max-width: 768px) {
  .s-header.s-header-slider .s-header-slider__slide {
    padding-top: 160px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header.s-header-slider h1 {
    text-align: center;
  }
}
.s-header.s-header-slider .s-header-slider__menu {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 10;
  max-width: 1540px;
  padding-left: 95px;
  padding-right: 95px;
  padding-top: 32px;
  padding-bottom: 32px;
}
@media only screen and (max-width: 1700px) {
  .s-header.s-header-slider .s-header-slider__menu {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .s-header.s-header-slider .s-header-slider__menu {
    width: 100%;
    padding-left: 30px !important;
    padding-right: 30px !important;
    max-width: inherit;
  }
}
@media only screen and (max-width: 800px) {
  .s-header.s-header-slider .s-header-slider__menu {
    align-items: flex-start;
  }
}
@media only screen and (max-width: 600px) {
  .s-header.s-header-slider .s-header-slider__menu {
    flex-wrap: wrap;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.s-header.s-header-slider .s-header-slider__contacts {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}
@media only screen and (max-width: 600px) {
  .s-header.s-header-slider .s-header-slider__contacts {
    justify-content: center;
    width: 100%;
  }
}
.s-header.s-header-slider .s-header-slider__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 32px;
  margin-top: 8px;
  margin-bottom: 8px;
}
@media only screen and (max-width: 600px) {
  .s-header.s-header-slider .s-header-slider__link {
    margin-left: 8px;
    margin-right: 8px;
  }
}
.s-header.s-header-slider .s-header-slider__link span {
  margin-left: 6px;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}
.s-header.s-header-slider .s-header-slider__slide {
  position: relative;
}
.s-header.s-header-slider .s-header__content {
  align-items: flex-start;
}
.s-header.s-header-slider .s-header__text-wrap,
.s-header.s-header-slider .s-header__blocks {
  width: 50%;
}
@media only screen and (max-width: 992px) {
  .s-header.s-header-slider .s-header__text-wrap,
  .s-header.s-header-slider .s-header__blocks {
    width: 100%;
  }
}
.s-header.s-header-slider .s-header-slider__logo {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .s-header.s-header-slider .s-header-slider__logo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
  }
}
.s-header.s-header-slider .s-header__blocks {
  margin-top: 30px;
}
.s-header.s-header-slider .s-header__blocks-header {
  width: calc(100% + 16px);
  margin-left: -8px;
}
.s-header.s-header-slider .s-header__blocks-header p {
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;
  color: #fff;
  padding: 8px 24px;
  backdrop-filter: blur(8px);
  width: calc(33.3% - 16px);
  background-color: rgba(255,255,255,0.05);
  margin: 8px;
}
@media only screen and (max-width: 1400px) {
  .s-header.s-header-slider .s-header__blocks-header p {
    width: calc(50% - 16px);
  }
}
@media only screen and (max-width: 992px) {
  .s-header.s-header-slider .s-header__blocks-header p {
    width: calc(33.3% - 16px);
  }
}
@media only screen and (max-width: 768px) {
  .s-header.s-header-slider .s-header__blocks-header p {
    width: calc(50% - 16px);
  }
}
@media only screen and (max-width: 500px) {
  .s-header.s-header-slider .s-header__blocks-header p {
    width: calc(100% - 16px);
  }
}
.s-header.s-header-slider .s-header__blocks-wrap {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 16px);
  margin-left: -8px;
}
.s-header.s-header-slider .s-header__block {
  padding: 24px 40px;
  backdrop-filter: blur(8px);
  width: calc(33.3% - 16px);
  background-color: rgba(255,255,255,0.1);
  margin: 8px;
  height: 152px;
}
@media only screen and (max-width: 1400px) {
  .s-header.s-header-slider .s-header__block {
    width: calc(50% - 16px);
  }
}
@media only screen and (max-width: 992px) {
  .s-header.s-header-slider .s-header__block {
    width: calc(33.3% - 16px);
  }
}
@media only screen and (max-width: 768px) {
  .s-header.s-header-slider .s-header__block {
    width: calc(50% - 16px);
  }
}
@media only screen and (max-width: 500px) {
  .s-header.s-header-slider .s-header__block {
    width: calc(100% - 16px);
  }
}
.s-header.s-header-slider .s-header__block-icon {
  margin-bottom: 8px;
}
.s-header.s-header-slider .s-header__block-title {
  font-size: 32px;
  line-height: 40px;
  color: #fff;
  font-weight: 800;
  margin: 0;
}
.s-header.s-header-slider .s-header__block-text {
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 8px;
}
.s-header.s-header-slider .s-header__block-img-wrap {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}
.s-header.s-header-slider .s-header__block-img {
  max-width: 100%;
}
.s-header.s-header-slider .slick-dots li {
  background-color: rgba(255,255,255,0.5);
  margin-left: 9px;
  margin-right: 9px;
  position: relative;
  width: 16px;
  height: 16px;
  overflow: visible;
}
.s-header.s-header-slider .slick-dots li.slick-active {
  background-color: #fff;
  border: 1px solid #fff;
  margin-left: 25px;
  margin-right: 25px;
}
.s-header.s-header-slider .slick-dots li.slick-active:after {
  position: absolute;
  content: '';
  display: block;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  border: 1px solid #fff;
  top: -17px;
  left: -17px;
}
.s-header.s-header-slider .slick-dots {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
}
.labeltag {
  display: none;
}
.s-description + .s-partners,
.s-our-customers + .s-partners {
  border-top: 1px solid #ddd;
}
.s-partners {
  position: relative;
  overflow: hidden;
  padding-top: 136px;
  padding-bottom: 136px;
}
@media only screen and (max-width: 991px) {
  .s-partners {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .s-partners {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.s-partners .container {
  padding-top: 0;
  padding-bottom: 0;
}
.s-partners .grey-overlay {
  position: absolute;
  height: 1000px;
  width: 2000px;
  background-color: #f6f6f6;
  transform: rotate(158deg);
  right: -430px;
  bottom: -820px;
  z-index: 2;
}
.s-partners h2 {
  color: #000;
  position: relative;
  z-index: 2;
}
.s-partners .partners-wrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 3;
}
.s-partners .partners-wrap .image__block {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 30px;
}
@media only screen and (max-width: 767px) {
  .s-partners .partners-wrap .image__block {
    height: 110px;
  }
}
.s-partners .partners-wrap .image__block img {
  max-width: 100%;
  max-height: 100%;
}
.s-partners.gray-bg {
  background-color: #f7f7f7 !important;
}
.s-services h2 {
  color: #000;
  max-width: 930px;
}
.s-services .section-description {
  max-width: 840px;
}
.s-services .s-services__blocks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.s-services .s-services__blocks .s-services__icon {
  height: 48px;
  display: flex;
  align-items: flex-end;
}
.s-services .s-services__blocks .s-services__icon svg {
  max-height: 100%;
}
.s-services .s-services__blocks .block {
  margin: 43px 0 20px;
  width: 46%;
}
@media only screen and (max-width: 991px) {
  .s-services .s-services__blocks .block {
    margin: 30px 0 20px;
  }
}
@media only screen and (max-width: 600px) {
  .s-services .s-services__blocks .block {
    width: 100%;
    margin: 20px 0 0;
  }
}
.s-services .s-services__blocks .block img {
  margin: 0 0 10px;
}
.s-services .s-services__blocks .block ul li {
  margin-bottom: 12px;
}
.s-services__btn-wrap {
  display: flex;
}
.s-services.s-services_black p,
.s-services.s-services_black h2 {
  color: #fff;
}
.s-services__btn {
  margin-top: 30px;
}
.s-services.grey-bg {
  background-color: #f7f7f7 !important;
}
.s-services.blocks .s-services__blocks .block {
  padding: 40px 32px;
  width: 23%;
  background-color: #fff;
}
@media only screen and (max-width: 992px) {
  .s-services.blocks .s-services__blocks .block {
    width: 48%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .s-services.blocks .s-services__blocks .block {
    width: 100%;
  }
}
.s-services.blocks .s-services__blocks .block h6 {
  margin-top: 24px;
}
.s-technology h2 {
  color: #000;
}
.s-technology .s-technology__content {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 991px) {
  .s-technology .s-technology__content .custom-tabs {
    display: flex;
    flex-wrap: wrap;
  }
  .s-technology .s-technology__content .custom-tabs .tab__result-wrap {
    order: 2;
    margin-top: 30px;
  }
  .s-technology .s-technology__content .custom-tabs .s-technology__logos {
    order: 1;
    margin-top: 0;
  }
}
.s-technology .s-technology__content .custom-tabs .tech-text {
  margin-bottom: 0;
  font-style: italic;
  font-size: 18px;
  line-height: 26px;
}
.s-technology .s-technology__content .custom-tabs .tech-text span {
  color: #00c088;
  font-size: 18px;
  line-height: 26px;
  font-family: 'proxima-nova-semibold';
}
.s-technology .s-technology__logos {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}
@media only screen and (max-width: 991px) {
  .s-technology .s-technology__logos {
    margin-top: 40px;
  }
}
.s-technology .s-technology__logos .logos-wrap {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
@media only screen and (max-width: 991px) {
  .s-technology .s-technology__logos .logos-wrap {
    justify-content: flex-start;
  }
}
.s-technology .s-technology__logos .logos-wrap .logo-block {
  width: 33%;
  height: 90px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 11px;
  margin-top: -1px;
  margin-left: -1px;
  transition: all 0.2s ease;
  cursor: pointer;
  filter: grayscale(1);
}
.s-technology .s-technology__logos .logos-wrap .logo-block.active {
  filter: none;
  box-shadow: 0 0 5px 3px rgba(0,0,0,0.1);
}
.s-technology .s-technology__logos .logos-wrap .logo-block:hover {
  filter: none;
}
@media only screen and (max-width: 450px) {
  .s-technology .s-technology__logos .logos-wrap .logo-block {
    width: 50%;
    height: 80px;
  }
}
.s-description p {
  color: #000;
}
.s-description .image__block {
  position: relative;
  margin-top: 60px;
  z-index: 2;
  margin-bottom: 32px;
}
.s-description .image__block img {
  height: 445px;
}
@media only screen and (max-width: 991px) {
  .s-description .image__block img {
    height: 430px;
  }
}
@media only screen and (max-width: 767px) {
  .s-description .image__block img {
    height: 340px;
  }
}
@media only screen and (max-width: 600px) {
  .s-description .image__block img {
    height: 270px;
  }
}
@media only screen and (max-width: 450px) {
  .s-description .image__block img {
    height: 200px;
  }
}
.s-description .image__block:after {
  content: '';
  display: block;
  position: absolute;
  width: 2000px;
  height: calc(100% + 64px);
  top: -32px;
  left: 32px;
  border: 1px solid #ddd;
  z-index: -1;
}
.s-description .s-description__abouts-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 46px;
  width: calc(100% + 30px);
  margin-left: -15px;
}
@media only screen and (max-width: 600px) {
  .s-description .s-description__abouts-wrap {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
}
.s-description .s-description__abouts-wrap .about__block {
  margin: 15px;
  width: calc(33.3% - 30px);
  border: 1px solid #ddd;
  padding: 40px;
}
@media only screen and (max-width: 991px) {
  .s-description .s-description__abouts-wrap .about__block {
    padding: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .s-description .s-description__abouts-wrap .about__block {
    width: calc(50% - 30px);
  }
}
@media only screen and (max-width: 600px) {
  .s-description .s-description__abouts-wrap .about__block {
    width: calc(50% - 20px);
    margin: 10px;
  }
}
@media only screen and (max-width: 450px) {
  .s-description .s-description__abouts-wrap .about__block {
    width: calc(100% - 20px);
  }
}
.s-description .s-description__abouts-wrap .about__block .about__header {
  margin-bottom: 6px;
  height: 46px;
}
.s-description .s-description__abouts-wrap .about__block .about__header svg {
  height: 100%;
}
.s-description .s-description__abouts-wrap .about__block .about__header span {
  font-family: 'proxima-nova-semibold';
  font-size: 46px;
  line-height: 46px;
}
.s-description .s-description__abouts-wrap .about__block .about__text p {
  margin: 0;
}
.s-baner {
  position: relative;
  z-index: 2;
}
.s-baner.grey-bg {
  background-color: #f7f7f7 !important;
}
.s-baner .container {
  padding-top: 75px;
  padding-bottom: 75px;
}
.s-baner .container .s-baner__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .s-baner .container .s-baner__content {
    flex-wrap: wrap;
  }
}
.s-baner .container .s-baner__content .s-baner__header-block {
  max-width: 800px;
  padding: 20px 20px 20px 80px;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .s-baner .container .s-baner__content .s-baner__header-block {
    max-width: inherit;
    width: 100%;
    padding: 0;
  }
}
.s-baner .container .s-baner__content .s-baner__header-block:before {
  content: '';
  display: block;
  width: 12px;
  height: 100%;
  background-color: #00c088;
  position: absolute;
  left: 0;
  top: 0;
}
@media only screen and (max-width: 991px) {
  .s-baner .container .s-baner__content .s-baner__header-block:before {
    display: none;
  }
}
.s-baner .container .s-baner__content .s-baner__header-block h4 {
  color: #000;
  font-family: 'proxima-nova-regular';
}
.s-baner .container .s-baner__content .s-baner__header-block h4 span {
  font-family: 'proxima-nova-regular';
  color: #000;
}
.s-baner .container .s-baner__content .s-baner__header-block h4 span.green {
  color: #00c088;
  background-color: transparent !important;
}
.s-baner .container .s-baner__content .s-baner__header-block h4 span.bold {
  font-family: 'proxima-nova-bold';
}
.s-baner .container .s-baner__content .btn_green {
  text-align: center;
  height: 74px;
}
@media only screen and (max-width: 991px) {
  .s-baner .container .s-baner__content .btn_green {
    margin-top: 28px;
  }
}
.s-baner.s-baner-black {
  background-color: #14171c;
}
.s-baner.s-baner-black .container .s-baner__content .s-baner__header-block h4 {
  color: #fff;
}
.s-baner.s-baner-black .container .s-baner__content .s-baner__header-block h4 span {
  color: #fff;
}
.s-baner.s-baner-black .container .s-baner__content .s-baner__header-block h4 span.green {
  color: #00c088;
}
.s-baner.s-baner-black .container .s-baner__content .btn_green {
  width: auto;
  padding-left: 24px;
  padding-right: 24px;
}
.s-baner.s-baner-calc {
  background-color: transparent;
  background: linear-gradient(90deg, #00c088 0%, #00d3bf 100%);
}
.s-baner.s-baner-calc .container .s-baner__content .s-baner__header-block h4 {
  font-family: 'proxima-nova-bold';
}
.s-baner.s-baner-calc .container .s-baner__content .s-baner__header-block:before {
  background-color: #fff;
}
.s-baner.s-baner-calc .container .s-baner__content .btn_green {
  background-color: #fff;
}
.s-baner.s-baner-calc .container .s-baner__content .btn_green span {
  color: #00caa4;
}
.s-baner.s-baner-thanks {
  background-color: transparent;
  background: linear-gradient(90deg, #00c088 0%, #00d3bf 100%);
  position: relative;
  overflow: hidden;
}
.s-baner.s-baner-thanks:before {
  content: '';
  display: block;
  width: 478px;
  height: 478px;
  position: absolute;
  right: 1370px;
  bottom: 50px;
  background: url("/assets/images/img/s-baner-elipse-1.png") center no-repeat;
  background-size: cover;
}
.s-baner.s-baner-thanks:after {
  content: '';
  display: block;
  width: 700px;
  height: 700px;
  position: absolute;
  left: 1090px;
  top: 16px;
  background: url("/assets/images/img/s-baner-elipse-1.png") center no-repeat;
  background-size: cover;
}
.s-baner.s-baner-thanks .container .s-baner__content .s-baner__header-block {
  position: relative;
  max-width: inherit;
  width: calc(100% - 270px);
  z-index: 2;
}
@media only screen and (max-width: 991px) {
  .s-baner.s-baner-thanks .container .s-baner__content .s-baner__header-block {
    width: 100%;
  }
}
.s-baner.s-baner-thanks .container .s-baner__content .s-baner__header-block h4 {
  font-family: 'proxima-nova-bold';
}
.s-baner.s-baner-thanks .container .s-baner__content .s-baner__header-block h4 span {
  color: #fff;
  font-family: inherit;
}
.s-baner.s-baner-thanks .container .s-baner__content .s-baner__header-block:before {
  background-color: #fff;
}
.s-baner.s-baner-thanks .container .s-baner__content .btn_green {
  position: relative;
  width: 256px;
  background-color: #fff;
  z-index: 2;
}
.s-baner.s-baner-thanks .container .s-baner__content .btn_green span {
  color: #00caa4;
}
.s-baner.s-baner-modal {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 150px;
  padding-bottom: 150px;
}
.s-baner.s-baner-modal .container .s-baner__content .s-baner__header-block h4 span {
  color: #fff;
}
.s-baner.s-baner-modal .container .s-baner__content .s-baner__header-block h4 span.green {
  color: #00c088;
}
@media only screen and (max-width: 1400px) {
  .modal__banner-form {
    width: 90%;
  }
}
.modal__banner-form .modal-content {
  padding: 0;
  position: relative;
}
.modal__banner-form .modal-cross-wrap {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  width: auto;
}
.modal__banner-form .modal-cross-wrap svg g {
  fill: #14171c;
}
@media only screen and (max-width: 992px) {
  .modal__banner-form .modal-cross-wrap {
    top: 0;
  }
}
.modal__banner-form .banner-form__wrap {
  display: flex;
}
@media only screen and (max-width: 992px) {
  .modal__banner-form .banner-form__wrap {
    flex-wrap: wrap;
  }
}
.modal__banner-form .banner-form__left-part {
  width: 40%;
  background-color: #14171c;
  padding: 90px 30px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 992px) {
  .modal__banner-form .banner-form__left-part {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.modal__banner-form .banner-form__right-part {
  width: 60%;
  padding: 90px 40px;
}
@media only screen and (max-width: 992px) {
  .modal__banner-form .banner-form__right-part {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.modal__banner-form .banner-form__text {
  color: #fff;
  font-size: 24px;
  line-height: 32px;
}
@media only screen and (max-width: 767px) {
  .modal__banner-form .banner-form__text {
    font-size: 18px;
    line-height: 26px;
  }
}
.modal__banner-form .banner-form__text span {
  color: #00c088;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
}
@media only screen and (max-width: 767px) {
  .modal__banner-form .banner-form__text span {
    font-size: 26px;
    line-height: 26px;
  }
}
.s-baner-new {
  background-color: #000 !important;
  background: url("/assets/images/img/bg_black.jpg") center no-repeat;
  background-size: cover;
  overflow: hidden;
}
.s-baner-new__text-wrap {
  max-width: 825px;
}
.s-baner-new__title {
  color: #fff;
}
.s-baner-new__img {
  position: absolute;
  top: 60px;
  right: 0;
}
@media only screen and (max-width: 1400px) {
  .s-baner-new__img {
    max-width: 600px;
  }
}
@media only screen and (max-width: 1200px) {
  .s-baner-new__img {
    display: none;
  }
}
.s-baner-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.s-baner-image__text {
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  color: #fff;
  max-width: 640px;
}
.s-baner-image .btn_green {
  margin-top: 40px;
}
.s-cases.grey-bg {
  background-color: #f7f7f7 !important;
}
.s-cases .section-description {
  margin-bottom: 0;
  margin-top: 0;
}
.s-cases .s-cases__slider .case {
  border: 1px solid #ddd;
  background-color: #fff;
}
.s-cases .s-cases__slider p {
  color: #000;
}
.s-cases .s-cases__slider .image {
  height: 330px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.s-cases .s-cases__slider .descr {
  padding: 25px 42px;
}
@media only screen and (max-width: 767px) {
  .s-cases .s-cases__slider .descr {
    padding: 20px 30px;
  }
}
.s-expertise-software .s-expertise-software__description {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  width: 100%;
  max-width: 1160px;
  text-transform: none;
  margin: 0 auto 40px;
  display: block;
}
.s-expertise-software .s-expertise-software-wrap {
  margin-top: 60px;
  margin-bottom: 35px;
}
.s-expertise-software .s-expertise-software-wrap .s-expertise-software-block {
  display: flex;
  margin: 10px 0;
}
.s-expertise-software .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-main:hover + .s-expertise-software-block-img-wrap .s-expertise-software-block-img {
  filter: grayscale(0);
  opacity: 1;
  border: 1px solid #00c088;
}
.s-expertise-software .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-main:hover {
  background-color: #00c088;
}
.s-expertise-software .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-main:hover span {
  color: #fff;
}
.s-expertise-software .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-main {
  flex: 1;
  width: 100%;
  height: 80px;
  background-color: #f9fafd;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.s-expertise-software .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-main span {
  font-size: 20px;
  font-family: 'proxima-nova-bold';
  color: #2d2b2f;
  text-transform: uppercase;
}
@media only screen and (max-width: 1240px) {
  .s-expertise-software .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-main span {
    font-size: 15px;
  }
}
.s-expertise-software .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-img-wrap {
  display: flex;
  flex-wrap: wrap;
}
.s-expertise-software .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-img-wrap .s-expertise-software-block-img:hover {
  border: 1px solid #00c088;
}
.s-expertise-software .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-img-wrap .s-expertise-software-block-img:hover:after {
  content: attr(data-title);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  background: RGBA(0, 192, 136, 0.7);
  font-family: Arial, sans-serif;
  font-size: 11px;
  padding: 5px 10px;
  color: #fff;
  display: flex;
}
.s-expertise-software .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-img-wrap .s-expertise-software-block-img {
  position: relative;
  cursor: pointer;
  height: 80px;
  padding: 20px;
  border: 1px solid #dfebf0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  transition: all 0.2s ease;
  margin-left: 10px;
}
.s-expertise-software .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-img-wrap .s-expertise-software-block-img img {
  max-width: 90px;
  max-height: 50px;
}
.s-expertise-software-block {
  overflow: hidden;
}
.s-expertise-software-block .s-expertise-software-block-main {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.75s ease !important;
}
.s-expertise-software-block .s-expertise-software-block-img-wrap {
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.75s ease;
}
.seen .s-expertise-software-block .s-expertise-software-block-main {
  opacity: 1;
  transform: translateX(0);
}
.seen .s-expertise-software-block .s-expertise-software-block-img-wrap {
  opacity: 1;
  transform: translateX(0);
}
@media only screen and (max-width: 1200px) {
  .s-expertise-software:not(.s-expertise-software_slider) .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-img-wrap .s-expertise-software-block-img {
    width: 106px;
  }
}
@media only screen and (max-width: 1060px) {
  .s-expertise-software:not(.s-expertise-software_slider) .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-img-wrap {
    width: 100%;
  }
  .s-expertise-software:not(.s-expertise-software_slider) .s-expertise-software-wrap .s-expertise-software-block {
    margin-bottom: 0;
    margin-top: 15px;
  }
  .s-expertise-software:not(.s-expertise-software_slider) .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-main span {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }
  .s-expertise-software:not(.s-expertise-software_slider) .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-main {
    height: 55px;
    min-width: 220px;
    max-width: 220px;
  }
  .s-expertise-software:not(.s-expertise-software_slider) .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-img-wrap .s-expertise-software-block-img {
    margin-left: 5%;
    height: 55px;
    margin-bottom: 15px;
    width: 20%;
  }
  .s-expertise-software:not(.s-expertise-software_slider) .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-img-wrap .s-expertise-software-block-img img {
    max-height: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .s-expertise-software:not(.s-expertise-software_slider) .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-img-wrap .s-expertise-software-block-img img {
    max-height: 40px;
  }
  .s-expertise-software:not(.s-expertise-software_slider) .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-img-wrap {
    justify-content: flex-start;
    width: 100%;
  }
  .s-expertise-software:not(.s-expertise-software_slider) .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-img-wrap .s-expertise-software-block-img:not(:nth-child(3n+1)) {
    margin-left: 5%;
  }
  .s-expertise-software:not(.s-expertise-software_slider) .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-img-wrap .s-expertise-software-block-img {
    margin-bottom: 10px;
    width: 30%;
    margin-left: 0;
  }
  .s-expertise-software:not(.s-expertise-software_slider) .s-expertise-software-wrap .s-expertise-software-block {
    flex-wrap: wrap;
    margin-top: 0;
  }
  .s-expertise-software:not(.s-expertise-software_slider) .s-expertise-software-wrap .s-expertise-software-block .s-expertise-software-block-main {
    min-width: 100%;
    margin-bottom: 10px;
    justify-content: center;
  }
}
.s-services-blocks {
  background: #f7f7f7;
}
.s-services-blocks__title {
  color: #000;
}
.s-services-blocks__blocks-wrap {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-top: 40px;
}
.s-services-blocks__block {
  padding: 48px 40px 68px;
  background: #fff;
  width: calc(100% / 2 - 32px);
  margin: 16px;
}
@media only screen and (max-width: 991px) {
  .s-services-blocks__block {
    width: calc(100% / 1 - 32px);
  }
}
@media only screen and (max-width: 400px) {
  .s-services-blocks__block {
    padding: 32px 24px 40px;
  }
}
.s-services-blocks__block-logo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  margin-bottom: 16px;
}
.s-services-blocks__block-logo-wrap img {
  max-height: 100%;
}
.s-services-blocks__block-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.25px;
  color: #000;
  margin-bottom: 4px;
  font-family: proxima-nova-semibold;
}
.s-services-blocks__block-text {
  font-size: 18px;
  line-height: 28px;
  color: #686868;
}
.s-services-blocks .s-services-blocks__block--contact {
  background: linear-gradient(270deg, #88d49b 0%, #5bb 100%);
}
.s-services-blocks .s-services-blocks__block--contact .s-services-blocks__block-contact-title {
  padding-top: 48px;
  position: relative;
  margin-bottom: 32px;
  font-size: 40px;
  line-height: 48px;
  color: #fff;
}
.s-services-blocks .s-services-blocks__block--contact .s-services-blocks__block-contact-title:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 8px;
  background-color: #fff;
}
.s-services-blocks .s-services-blocks__block--contact .s-services-blocks__block-contact-btn-wrap {
  display: flex;
}
.s-services-blocks .s-services-blocks__block--contact .s-services-blocks__block-contact-btn-wrap .btn_green {
  padding: 16px 40px;
  background-color: #fff !important;
}
.s-services-blocks .s-services-blocks__block--contact .s-services-blocks__block-contact-btn-wrap .btn_green span {
  font-size: 20px;
  line-height: 33px;
  text-align: center;
  color: #00c088;
  font-family: proxima-nova-semibold;
}
.s-services-blocks .animated-block {
  opacity: 0;
  transition: all 0.3s ease;
}
.s-services-blocks .animated-block:nth-child(odd) {
  transform: translateX(-100%);
}
.s-services-blocks .animated-block:nth-child(even) {
  transform: translateX(100%);
}
.s-services-blocks .animated-block.seen {
  opacity: 1 !important;
  transform: none !important;
}
